<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="BellIcon"/>
              <span>Bildirim Bilgileri</span>
            </template>

            <b-row class="mt-1">
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Başlık" rules="required">
                  <b-form-group label="Başlık">
                    <b-form-input trim placeholder="Başlık" v-model="dataInfo.title" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="validationContext" name="İçerik" rules="required">
                  <b-form-group label="İçerik">
                    <b-form-input trim placeholder="İçerik" v-model="dataInfo.body" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon"/>
              <span>Kullanıcı Bilgileri</span>
            </template>

            <b-alert variant="warning" show class="mb-1">
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Kullanıcı bilgilerini belirtmediğiniz durumda bildirim tüm kullanıcılara iletilecektir.</span></small>
                </p>
              </div>
            </b-alert>

            <b-card no-body class="border mt-1">
              <b-table
                  :items="dataInfo.targets"
                  :fields="targetFields"
                  class="mb-0"
                  striped responsive bordered hover>
                <template #cell(profilePicture)="data">
                  <b-avatar
                      ref="previewEl"
                      :src="getApiFile(data.value)"
                      :text="avatarText(data.item.name)"
                      :variant="`light-primary`"
                      size="30px"
                  />
                </template>
                <template #cell(isTarget)="data">
                  <b-form-checkbox :checked="data.item.isTarget" @change="changeTargetValue(data.item)"/>
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
        <action-buttons :back-route="'system-notification-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BTabs, BTab, BTable, BFormCheckbox, BAvatar, BAlert,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/notification/store"
import userModule from "@/views/system/user/store";
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    BAlert,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BTable,
    BFormCheckbox,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      title: '',
      body: '',
      targetType: 0,
      targets: []
    })

    busy.value = true
    store.dispatch('store-user/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        dataInfo.value.targets.push({
          targetId: value.id,
          name: value.firstName + ' ' + value.lastName,
          profilePicture: value.profilePicture,
          isTarget: false,
        })
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/addItem', dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'system-notification-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const targetFields = [
      {key: 'profilePicture', label: 'Profil', thStyle: {width: "5%"}},
      {key: 'name', label: 'Ad Soyad'},
      {key: 'isTarget', label: 'Gönderim', thStyle: {width: "10%"}}
    ];

    const changeTargetValue = (target) => {
      dataInfo.value.targets.forEach(function callback(value, index) {
        if (value.targetId === target.targetId) {
          value.isTarget = !target.isTarget
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      targetFields,
      refFormObserver,

      onSubmit,
      getValidationState,
      resetForm,
      changeTargetValue,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
